<script setup
        lang="ts">
import { defineAsyncComponent, ref } from 'vue';
import { useInteraction }            from '@reshepe/hooks';

let Gdpr: ReturnType<typeof defineAsyncComponent>;

const loaded = ref(false);

useInteraction(async () => {
    Gdpr = defineAsyncComponent(() => import('@reshepe/ui/gdpr.vue'));

    loaded.value = true;
});
</script>

<template>
    <Gdpr v-if="loaded" />
</template>
